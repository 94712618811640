import "./Header.css";
import { uiActions } from "../../store/slices/ui-slice.js";
import { useDispatch, useSelector } from "react-redux";
 
function Header({ title }) {

  const dispatch = useDispatch();
  const toggleMobileMenuHandler = () => {
    dispatch(uiActions.toggleMobileMenu());
  }

  const toggleSideMenuHandler = () => {
    dispatch(uiActions.toggleSideMenu());
  }

  const showMobileMenu = useSelector(state => state.ui.mobileMenuVisible)
  const showSideMenu = useSelector(state => state.ui.sideMenuVisible);

  const isMobileMenuActive = showMobileMenu ? "is-active" : "";
  const isSideMenuActive = showSideMenu ? "is-active" : "";

  return (
    <header className="header">
      <button className={`sidehamburger ${isSideMenuActive}`} onClick={toggleSideMenuHandler}>
        <div className="bar"></div>
      </button>     
      <div className="headerDiv">        
        <a href="/"><img src="/images/40.png" alt="logo" />{title}</a>
      </div>       
      <button className={`hamburger ${isMobileMenuActive}`} onClick={toggleMobileMenuHandler}>
        <div className="bar"></div>
      </button>      
    </header>
  );
}

export default Header;
