import BlogPage from "../BlogPage/BlogPage";
import { uiActions } from "../../store/slices/ui-slice"
import { useDispatch } from "react-redux"
function BitcoinPage() {
    const dispatch = useDispatch();
    dispatch(uiActions.setActivePage('bitcoin'));
    const bitcoinPostId = "749108287143870500";    
    return (
      <>
        <BlogPage postCount={1} postId={bitcoinPostId} showHeaderFooter={false} blogPage={false} />
      </>
    );

  }  
  export default BitcoinPage;
  