import { createSlice } from '@reduxjs/toolkit';

const uiSlice = createSlice({
    name: 'ui',
    initialState: { 
        sideMenuVisible: true,
        mobileMenuVisible: false,
        activePage: 'home'
    },
    reducers: {
        toggleMobileMenu(state) {
            state.mobileMenuVisible = !state.mobileMenuVisible;
        },
        toggleSideMenu(state) {
            state.sideMenuVisible = !state.sideMenuVisible;
        },
        setActivePage(state, action) {
            state.activePage = action.payload;
        }
    }
});

export const uiActions = uiSlice.actions;

export default uiSlice;