import { uiActions } from "../../store/slices/ui-slice"
import { useDispatch } from "react-redux"
function DetailsPage() {

  //Set the active page.
  const dispatch = useDispatch();
  dispatch(uiActions.setActivePage('details'));

  return (
    <>
      <div class="pageItem">
        <h2>Details Page</h2>
        <p>Some details about me.</p>
      </div>
    </>
  );
}

export default DetailsPage;