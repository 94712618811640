function BlogItem({ title, postId, postBody, blogDate, blogLink, showHeaderFooter }) {

    const getHeader = () => {        
        return (
            showHeaderFooter === true ? <p>{blogDate}</p> : ''
        );
    }

    const getFooter = () => {
        return (
            showHeaderFooter === true ? <p><br />Direct Link: <a href={blogLink} target='_blank'>Blog Post</a></p> : ''
        )
    }

    return (
        <div className='blogitem' key={postId}>
            <h2>{title}</h2>   
            {getHeader()}
            <div className='blogitemtext' dangerouslySetInnerHTML={{ __html: postBody }}></div>
            {getFooter()}
        </div>
    )
}

export default BlogItem;