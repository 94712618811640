import { uiActions } from "../../store/slices/ui-slice"
import { useDispatch } from "react-redux"
function ContactPage() {

  //Set the active page.
  const dispatch = useDispatch();
  dispatch(uiActions.setActivePage('contact'));

    return (
      <>
      <div class="pageItem">
      <h2>Contact Page</h2>
        <p>Get in touch with me!</p>

      </div>
      </>
    );
  }
  
  export default ContactPage;
  