import AWS from 'aws-sdk';

const getSecret = (parameterName) => {
  return new Promise((resolve, reject) => {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
    });
    const params = {
      Name: parameterName,
      WithDecryption: true
    };
    const ssm = new AWS.SSM({
      region: 'eu-west-2'
    });
    ssm.getParameter(params, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data.Parameter.Value);
      }
    });
  });
}

export default getSecret;
