import { useSelector } from "react-redux"
export default function MenuItem( { className, handleClickTarget, buttonText } ) {

  const activePage = useSelector(state => state.ui.activePage);

  function getClassNames(){    
    let returnString = className;
    if (activePage === handleClickTarget) {
      return `${returnString} active`;
    }
    return returnString;
  }

  const hrefTarget = () => {
    return `/${handleClickTarget === 'home' ? '' : handleClickTarget}`;
  }

  return (
    <a
      href={hrefTarget()}
      className={getClassNames()}
    >
    {buttonText}
    </a>  
  )
} 