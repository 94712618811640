  import React, { useState, useEffect } from 'react';
  import getSecret from '../../utils/secretsHelper'
  import axios from 'axios';
  import "./BlogPage.css";
  import BlogItem from "../BlogItem/BlogItem";
  import { uiActions } from "../../store/slices/ui-slice"
  import { useDispatch } from "react-redux"
  
  const blogName = 'modetry';
  
  function BlogPage({ postCount, postId, showHeaderFooter, blogPage }) {
    const dispatch = useDispatch();

    if (blogPage !== false){
      dispatch(uiActions.setActivePage('blog'));
    }    

    const postLimit = postCount ? postCount : 20;
    const requestedPostId = postId ? postId : undefined;
    const showHF = showHeaderFooter === false ? false : true;

    const [posts, setPosts] = useState([]);
  
    let responsePosts;

    useEffect(() => {
      getSecret("tumblr")
        .then((value) => {
          axios.get(`https://api.tumblr.com/v2/blog/${blogName}.tumblr.com/posts`, {
            params: {
              api_key: value,
            },
          })
            .then(response => {                            
              responsePosts = response.data.response.posts;
              if (requestedPostId) {
                const filteredPosts = responsePosts.filter((post) => post.id == requestedPostId);
                setPosts(filteredPosts.slice(0, postLimit));
              } else {
                setPosts(responsePosts.slice(0, postLimit));
              }              
            })
            .catch(error => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }, []);
  
    return (
      <div className='bloglist'>
        {posts.map(post => (
          <BlogItem key={post.id} title={post.title} postId={post.id} blogDate={post.date} blogLink={post.post_url} postBody={post.body} showHeaderFooter={showHF} />
        ))}
      </div>
    );
  }
  
  export default BlogPage;
  