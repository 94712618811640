import React from 'react';
import "./HomePage.css";
import BlogPage from '../BlogPage/BlogPage';

const HomePage = () => {
  const mainPostId = "749108287143870500";
  return (
    <>
      <div className="pageItem">
        <h1>modetry</h1>
        <p>Full Stack Cloud (AWS) Web developer.</p>
      </div>
      <BlogPage postCount={1} postId={mainPostId} showHeaderFooter={false} blogPage={false} />
    </>
  );
};

export default HomePage;
