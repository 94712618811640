import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./app.css";

// Header and Menus
import Header from "./components/Header/Header";
import SidebarMenu from "./components/SidebarMenu/SidebarMenu";
import MobileMenu from "./components/MobileMenu/MobileMenu";

// Pages
import HomePage from "./components/HomePage/HomePage";
import DetailsPage from "./components/DetailsPage/DetailsPage";
import BitcoinPage from "./components/BitcoinPage/BitcoinPage";
import BlogPage from "./components/BlogPage/BlogPage";
import ContactPage from "./components/ContactPage/ContactPage";
import { useSelector } from "react-redux"

function App() {
  const sideMenuVisible = useSelector(state => state.ui.sideMenuVisible);
  return (
    <Router>
      <div className="App">
        <Header 
          title="modetry"
        />
        <SidebarMenu />
        <MobileMenu />        
        <div className={`main-content ${sideMenuVisible ? "menu-open" : ""}`}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/details" element={<DetailsPage />} />
            <Route path="/bitcoin" element={<BitcoinPage />} />
            <Route path="/blog" element={<BlogPage />} />            
            <Route path="/contact" element={<ContactPage />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
