import MenuItem from "../MenuItem/MenuItem.js";
import { useDispatch, useSelector } from "react-redux"
import { uiActions } from "../../store/slices/ui-slice.js";
 
function Menu( { menuClass, stateClass } ) {

  const dispatch = useDispatch();

  const showSideMenu = useSelector(state => state.ui.sideMenuVisible);
  const showMobileMenu = useSelector(state => state.ui.mobileMenuVisible); 

  const isActive = stateClass === 'mobile-nav' ? 
    showMobileMenu ? "is-active" : "" :
    showSideMenu ?  "is-active" : "";
  
  const handleToggleMobileMenu = () => {
    dispatch(uiActions.toggleMobileMenu());
  }

  return (
    <div className={`${stateClass} ${isActive}`}>
      {stateClass === 'mobile-nav' && <h1 className="menuTitle">modetry</h1>}
      <MenuItem className={menuClass} handleClickTarget='home' buttonText='Home'></MenuItem>
      <MenuItem className={menuClass} handleClickTarget='details' buttonText='Details'></MenuItem>
      <MenuItem className={menuClass} handleClickTarget='bitcoin' buttonText='Bitcoin'></MenuItem>
      <MenuItem className={menuClass} handleClickTarget='blog' buttonText='Blog'></MenuItem>
      <MenuItem className={menuClass} handleClickTarget='contact' buttonText='Contact'></MenuItem>
      {stateClass === 'mobile-nav' && <div className="closeMobileMenu" onClick={handleToggleMobileMenu}>X</div>}
    </div>
  );
}

export default Menu;